import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

export default class Status extends Component {
  constructor(props) {
    super(props);
    this.adminMode = props.adminMode || false;
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  handleStatusChange(event, result) {
    const status = result.value;
    const { onChange } = this.props;

    onChange(status);
  }

  render() {
    const allOptions = [
      { key: 'PROGRESS', text: '작업중', value: 'PROGRESS', adminOnly: false },
      { key: 'PAUSE', text: '중지', value: 'PAUSE', adminOnly: false },
      { key: 'INSPECT', text: '검수중', value: 'INSPECT', adminOnly: true },
      {
        key: 'INSPECTCOMPLETE',
        text: '검수완료',
        value: 'INSPECTCOMPLETE',
        adminOnly: true,
      },
      {
        key: 'COMPLETE',
        text: '라벨완료',
        value: 'COMPLETE',
        adminOnly: false,
      },
    ];

    const options = allOptions.map(option => ({
      key: option.key,
      text: option.text,
      value: option.value,
      disabled: this.adminMode ? false : option.adminOnly,
    }));

    const { status } = this.props;

    return (
      <Form style={{ margin: '0 auto', marginBottom: 16 }}>
        <Form.Dropdown
          selection
          value={status}
          options={options}
          onChange={this.handleStatusChange}
          placeholder="Choose an option"
        />
      </Form>
    );
  }
}
