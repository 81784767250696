import React, { Component } from 'react';

import { Header, List, Input, Button } from 'semantic-ui-react';

export default class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      isEdit: false,
      editId: '',
      editCmt: '',
    };

    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleCommentAdd = this.handleCommentAdd.bind(this);
    this.handleCommentDelete = this.handleCommentDelete.bind(this);
    this.handleCommentUpdate = this.handleCommentUpdate.bind(this);
    this.handleEditCommentChange = this.handleEditCommentChange.bind(this);
    this.handleEditedCommentSave = this.handleEditedCommentSave.bind(this);
    this.handleEditedCommentReset = this.handleEditedCommentReset.bind(this);
  }

  handleCommentChange(e) {
    console.log('handleCommentChang', e);
    e.preventDefault();
    this.setState({ comment: e.target.value });
  }

  handleCommentAdd() {
    const { comment } = this.state;
    const { onSubmit } = this.props;

    onSubmit(comment);
    this.setState({ comment: '' });
  }

  handleCommentDelete(id) {
    const { onDelete } = this.props;

    onDelete(id);
  }

  handleCommentUpdate(cmt) {
    this.setState(prev => {
      return {
        ...prev,
        isEdit: true,
        editId: cmt.id,
        editCmt: cmt.comment,
      };
    });
  }

  handleEditCommentChange(e) {
    e.preventDefault();
    this.setState({
      editCmt: e.target.value,
    });
  }

  // 수정 내용 관리 state 초기화
  handleEditedCommentReset() {
    this.setState(prev => {
      return {
        ...prev,
        isEdit: false,
        editId: '',
        editCmt: '',
      };
    });
  }

  // 수정 완료
  handleEditedCommentSave() {
    const { onUpdate } = this.props;

    this.props.comments.forEach(cmt => {
      if (cmt.id === this.state.editId) {
        // 수정된 코멘트를 기존 코멘트에 할당
        cmt.comment = this.state.editCmt;
        // 날짜도 현재 날짜로 수정
        cmt.createdAt = Date.now();
      }
    });

    // 변경된 정보 서버에 요청
    onUpdate(this.state.editId, this.state.editCmt);

    // 수정할때 사용했던 state 값 초기화
    this.handleEditedCommentReset();
  }

  convertTime(date) {
    let dateData = new Date(date);

    const year = dateData.getFullYear();
    const month = dateData.getMonth() + 1;
    const day = dateData.getDate();
    const hour = dateData.getHours();
    const min = dateData.getMinutes();
    const sec = dateData.getSeconds();

    function checkDate(value) {
      return value < 10 ? `0${value}` : value;
    }

    const setDate = `${year}-${checkDate(month)}-${checkDate(day)}`;
    const setTime = `${checkDate(hour)}:${checkDate(min)}:${checkDate(sec)}`;

    return `${setDate} ${setTime}`;
  }

  render() {
    const { comment, isEdit, editId, editCmt } = this.state;
    const { comments, allowSubmit } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxWidth: '300px',
        }}
      >
        <Header size="large">피드백 목록</Header>

        <List divided selection style={{ flex: 1, overflowY: 'auto' }}>
          {comments.map((cmt, i) => {
            const formattedDate = this.convertTime(cmt.createdAt);

            return (
              <List.Item key={i}>
                {isEdit && editId === cmt.id ? (
                  <Input
                    id={'commentInput'}
                    value={editCmt}
                    onChange={this.handleEditCommentChange}
                  />
                ) : (
                  <List.Content style={{ color: '#000' }}>
                    {cmt.comment}
                  </List.Content>
                )}

                <List.Description
                  style={{
                    textAlign: 'right',
                    color: '#CCC',
                    marginTop: 8,
                  }}
                >
                  {formattedDate}
                </List.Description>
                {allowSubmit && (
                  <Button
                    icon="trash"
                    label="삭제"
                    size="tiny"
                    style={{ float: 'right', marginTop: '8px' }}
                    onClick={() => this.handleCommentDelete(cmt.id)}
                  />
                )}
                {allowSubmit &&
                  (!isEdit || editId !== cmt.id ? (
                    <Button
                      icon="pencil"
                      label="수정"
                      size="tiny"
                      style={{ float: 'right', marginTop: '8px' }}
                      onClick={() => this.handleCommentUpdate(cmt)}
                    />
                  ) : (
                    <Button
                      icon="pencil"
                      label="완료"
                      size="tiny"
                      style={{ float: 'right', marginTop: '8px' }}
                      onClick={this.handleEditedCommentSave}
                    />
                  ))}
              </List.Item>
            );
          })}
        </List>

        <footer>
          <Input
            id={'commentInput'}
            placeholder={
              allowSubmit ? '검수 후 피드백을 남기세요.' : '피드백이 없습니다.'
            }
            error
            disabled={!allowSubmit}
            control="input"
            value={comment}
            style={{ fontSize: 12, width: '100%', marginTop: 10 }}
            onChange={this.handleCommentChange}
            onFocus={e => {
              console.log('focus', e);
              e.preventDefault();
              // this.props.focus();
            }}
            onBlur={e => {
              e.preventDefault();
              // this.props.blur();
            }}
          />
          {allowSubmit && (
            <Button
              primary
              style={{ float: 'right', marginTop: '2em' }}
              onClick={this.handleCommentAdd}
            >
              추가
            </Button>
          )}
        </footer>
      </div>
    );
  }
}
