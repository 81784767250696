import React, { Component } from 'react';
import SideBar from '../admin/SideBar';

class ImageList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      currentImage: null,
      images: [],
      error: null,
    };
  }

  componentDidMount() {
    const { projectId, currentImageId } = this.props;

    fetch('/api/images/?projectId=' + projectId)
      .then(res => {
        return res.json();
      })
      .then(data => {
        // get current image
        function getCurrentImageIfExist() {
          const imgs = data.filter(d => d.id === currentImageId);
          if (imgs.length === 1) {
            return imgs[0];
          } else {
            return null;
          }
        }

        this.setState({
          isLoaded: true,
          images: data,
          currentImage: getCurrentImageIfExist(data),
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { onImageClicked } = this.props;
    const { images, currentImage } = this.state;

    return (
      <>
        {images.length > 0 ? (
          <SideBar
            images={images}
            currentImage={currentImage}
            onImageClicked={onImageClicked}
          />
        ) : (
          <div>loding</div>
        )}
      </>
    );
  }
}

export default ImageList;
