export const colorMapping = {
  red: '#db2828',
  orange: '#ff7400',
  yellow: '#ffd700',
  olive: '#749645',
  green: '#74ee15',
  teal: '#008080',
  blue: '#0E6EB8',
  violet: '#8f8dff',
  purple: '#b967ff',
  pink: '#ff71ce',
  brown: '#854442',
  gray: '#A0A0A0',
  black: '#000000',
  red2: '#e24242',
  orange2: '#ecc06d',
  yellow2: '#ffe34c',
  olive2: '#9db57c',
  green2: '#9df35b',
  teal2: '#66b2b2',
  blue2: '#6ea8d4',
  violet2: '#49564d',
  purple2: '#dcb3ff',
  pink2: '#ffb8e6',
  brown2: '#b58e8d',
  gray2: '#d9d9d9',
  black2: '#4c4c4c',
  red3: '#bb0000',
  orange3: '#398300',
  yellow3: '#dba1cc',
  olive3: '#39433b',
  green3: '#e0c93b',
  teal3: '#e0ff3b',
  brown3: '#8af8ab',
  blue3: '#4994cc',
  violet3: '#e0ff03'
};

export function convertPoint(p) {
  return {
    lat: p.lat,
    lng: p.lng,
  };
}

export function lighten(col, amt) {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function genId() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
}

export const shortcuts = '1234567890qwertyuiopasdfghjklzxc';
export const colors = [
  'red',
  'blue',
  'green',
  'violet',
  'orange',
  'brown',
  'yellow',
  'olive',
  'teal',
  'purple',
  'pink',
  'black',
  'red2',
  'blue2',
  'green2',
  'violet2',
  'orange2',
  'brown2',
  'yellow2',
  'olive2',
  'teal2',
  'purple2',
  'pink2',
  'black2',
  'red3',
  'blue3',
  'green3',
  'violet3',
  'orange3',
  'brown3',
  'yellow3',
  'olive3',
  'teal3',
  'violet3'
];
