import React from 'react';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';

const style = {
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  borderRight: '1px solid #ccc',
};
const headerIconStyle = { fontSize: '0.8em', float: 'right' };

export default function HotkeysPanel({ labels, onClose }) {
  const convert = (number) => {
    switch(number) {
      case 9: return 0
      case 10: return 'q'
      case 11: return 'w'
      case 12: return 'e'
      case 13: return 'r'
      case 14: return 't'
      case 15: return 'y'
      case 16: return 'u'
      case 17: return 'i'
      case 18: return 'o'
      case 19: return 'p'
      case 20: return 'a'
      case 21: return 's'
      case 22: return 'd'
      case 23: return 'f'
      case 24: return 'g'
      case 25: return 'h'
      case 26: return 'j'
      case 27: return 'k'
      case 28: return 'l'
      case 29: return 'z'
      case 30: return 'x'
      case 31: return 'c'
      default: return ++number
    }
  }
  const labelHotkeys = labels.map((label, i) => (
    <Table.Row key={label}>
      <Table.Cell>{label}</Table.Cell>
      <Table.Cell>{convert(i)}</Table.Cell>
    </Table.Row>
  ));

  return (
    <div style={style}>
      <Header as="h2" attached="top">
        단축키
        <Icon link name="close" style={headerIconStyle} onClick={onClose} />
      </Header>
      <Segment attached>
        <Header as="h3"> 라벨 </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>기능</Table.HeaderCell>
              <Table.HeaderCell>단축키</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{labelHotkeys}</Table.Body>
        </Table>
        <Header as="h3"> 어노테이션 </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>기능</Table.HeaderCell>
              <Table.HeaderCell>단축키</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>완성</Table.Cell>
              <Table.Cell>ctrl+f</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>삭제</Table.Cell>
              <Table.Cell>Delete</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>선택 취소</Table.Cell>
              <Table.Cell>Escape</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>라벨완료</Table.Cell>
              <Table.Cell>Space</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>검수완료</Table.Cell>
              <Table.Cell>shift+Space</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>반려</Table.Cell>
              <Table.Cell>shift+z</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>되돌리기</Table.Cell>
              <Table.Cell>ctrl+z</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>끄키/켜기</Table.Cell>
              <Table.Cell>`</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h3"> 화면 </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>기능</Table.HeaderCell>
              <Table.HeaderCell>단축키</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>확대</Table.Cell>
              <Table.Cell>+/=</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>축소</Table.Cell>
              <Table.Cell>-</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h3"> 이동 </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>기능</Table.HeaderCell>
              <Table.HeaderCell>단축키</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>이전</Table.Cell>
              <Table.Cell>left</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>다음</Table.Cell>
              <Table.Cell>right</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>다음 미완료</Table.Cell>
              <Table.Cell>up</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>이전 미완료</Table.Cell>
              <Table.Cell>down</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}
